import styles from './DotIndicators.module.scss'
import classNames from 'classnames'

function DotIndicators({
  size,
  activeIndex,
  classNameIndicatorDots,
  classNameActiveDot,
  classNameIndicators,
  variant,
}) {
  let indicators = []
  for (let id = 0; id < size; id++) {
    indicators.push(
      <div
        key={id}
        className={classNames(styles['dot-controls-dot'], {
          [classNameIndicatorDots]: Boolean(classNameIndicatorDots),
          [styles['dot-controls-dot-active']]: activeIndex === id,
          [classNameActiveDot]: Boolean(classNameActiveDot) && activeIndex === id,
        })}
      />
    )
  }

  return (
    <div
      data-variant={variant}
      className={classNames(classNameIndicators, styles['dot-controls'])}
      role="group"
    >
      {indicators}
    </div>
  )
}

export { DotIndicators }
