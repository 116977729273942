import Section from 'components/Section'
import { Typography, MediaImageStandard } from 'components/Contentful'
import { AchieveTheme, Grid, Paper } from '@achieve/ascend'
import { AchieveLink } from 'components/AchieveLink/AchieveLink'
import { BLOCKS } from '@contentful/rich-text-types'
import { useViewportSmallerThan } from 'utils/viewport'
import { BREAKPOINTS } from 'constants/viewport'
import { TitleDivider } from 'components/TitleDivider'
import { Carousel } from 'components/Carousels'
import styles from './PaperCarousel.module.scss'
import { rewriteAssetUrl } from 'utils/conversions/urls'

function Card({
  idPrefix,
  item: { cardImage, cardText, cardLink },
  priority,
  index,
  listNameTracking,
}) {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.xl)
  const [leadText, heading, supportingText] = cardText?.json?.content ?? []
  const headingText = typeof heading === 'string' ? heading : heading?.content[0].value
  const { linkText, linkHref, downloadFile } = cardLink || {}
  const assetUrl = rewriteAssetUrl(downloadFile?.fields?.file?.url)
  const CardContent = (
    <Grid
      container
      direction={{ xs: 'column', lg: 'row' }}
      justifyContent={{ xs: 'flex-start', lg: 'flex-start' }}
      alignItems={{ xs: 'stretch', lg: 'center' }}
      className={styles['outer-grid-container']}
    >
      <Grid item xs={12} lg={7} className={styles['grid-item-image']}>
        <div className={styles['img-container']}>
          <span>
            <MediaImageStandard
              content={cardImage}
              objectFit="cover"
              height={isMobile ? 300 : 341}
              objectPosition="top"
              priority={priority}
              className={styles.image}
            />
          </span>
        </div>
      </Grid>
      <Grid container item xs={12} lg={5}>
        <Grid item xs={12}>
          <Typography
            content={leadText}
            data-testid={`${idPrefix}-lead-text`}
            className={styles['lead-text']}
            variant={isMobile ? 'bodySm' : 'bodyLg'}
          />
        </Grid>
        <Grid item xs={12}>
          {assetUrl ? (
            <Typography
              content={heading}
              data-testid={`${idPrefix}-heading-text`}
              variant={isMobile ? 'bodyLg' : 'headingMd'}
              className={styles['heading-text']}
              fontWeight="medium"
            />
          ) : (
            <>
              <AchieveLink
                href={linkHref}
                className={styles['title-link']}
                noLink
                track={{
                  list_name: 'Recommended Cards Carousel Link',
                  click_position: index,
                  click_text: `Achieve-Web | ${headingText}`,
                  click_id: headingText,
                }}
              >
                <Typography
                  content={heading}
                  data-testid={`${idPrefix}-heading-text`}
                  variant={isMobile ? 'bodyLg' : 'headingMd'}
                  className={styles['heading-text']}
                  fontWeight="medium"
                />
              </AchieveLink>
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography
            content={supportingText}
            data-testid={`${idPrefix}-supporting-text`}
            fontWeight="regular"
            className={styles['supporting-text']}
            variant="bodySm"
          />
        </Grid>
        <Grid item xs={12}>
          {assetUrl ? (
            <AchieveLink
              href={assetUrl}
              target="blank"
              download
              className={styles['link']}
              noLink
              track={{
                list_name: listNameTracking,
                click_position: index,
                click_text: `Achieve-Web | ${cardLink?.title?.content[0]?.content[0]?.value}`,
                click_id: cardLink?.title?.content[0]?.content[0]?.value,
              }}
            >
              <Typography
                className={styles.title}
                content={cardLink?.title}
                variant={isMobile ? 'bodySm' : 'bodyLg'}
                fontWeight={isMobile ? 'medium' : 'bold'}
                component="h3"
              />
            </AchieveLink>
          ) : (
            <AchieveLink
              href={linkHref}
              className={styles['link']}
              noLink
              track={{
                list_name: listNameTracking,
                click_position: index,
                click_text: `Achieve-Web | ${linkText?.json?.content?.[0]?.content[0]?.value}`,
                click_id: linkText?.json?.content?.[0]?.content[0]?.value,
                click_url: linkHref,
              }}
            >
              <Typography
                content={linkText}
                fontWeight={isMobile ? 'medium' : 'bold'}
                variant={isMobile ? 'bodySm' : 'bodyLg'}
                styleOverride={{
                  [BLOCKS.PARAGRAPH]: {
                    marginTop: '1em',
                  },
                }}
              />
            </AchieveLink>
          )}
        </Grid>
      </Grid>
    </Grid>
  )

  return isMobile ? (
    <>{CardContent}</>
  ) : (
    <Paper elevation={0} className={styles['paper']}>
      <>{CardContent}</>
    </Paper>
  )
}

function PaperCarousel({
  title,
  carouselItems,
  theme,
  idPrefix = 'page',
  eyebrow,
  listNameTracking = null,
}) {
  // we are using anything smaller than lg as our mobile for this one
  // for now though, we will make a medium screen resolution later
  // *** UPDATE 7/8/22 ***
  // The lg breakpoint was changed (now 1024px) and xl (1280) is closer to the old lg (1200).
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  const sectionIdPrefix = `${idPrefix}-more-resources-carousel`
  const backgroundColor =
    theme?.theme[0] == 'light-blue'
      ? AchieveTheme?.ascend?.colors?.blue?.[50]
      : AchieveTheme?.ascend?.colors?.neutral?.[0]
  const variant = {
    title: isMobile ? 'headingMd' : 'displayLg',
    titleFontWeight: 'medium',
    subtitle: isMobile ? 'bodySm' : 'displaySm',
    subtitleFontWeight: 'regular',
  }
  return (
    <Section contain={false} className={styles['section']} backgroundColor={backgroundColor}>
      <TitleDivider
        eyebrow={eyebrow}
        className={styles['paper-carousel-title-divider']}
        title={title}
        data-testid={`${idPrefix}-title`}
        variants={variant}
      />
      <Carousel
        carouselItems={carouselItems}
        itemComponent={Card}
        variant="preview"
        idPrefix={sectionIdPrefix}
        className={styles['shadow-selected']}
        currentClassName={styles['selected-item']}
        withIndicators={isMobile}
        classNameIndicators={styles['indicators']}
        classNameActiveDot={styles['active-indicator']}
        dataItem={{ listNameTracking }}
      />
    </Section>
  )
}

export { PaperCarousel }
